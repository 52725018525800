export const environment = {
    env: 'PROD',
    production: true,
    serverBaseUrl: 'https://api.public-eye.digibits.xyz/api/',
    serverUrl: 'https://api.public-eye.digibits.xyz/api/',
    pusherKey: 'c666ef11-9ca8-4e13-baec-472e2350a2cf',
    pusherHost:  '127.0.0.1',
    pusherPort: 6001,
    pusherCluster: 'mt1',
  };
