import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-ag-set-filter',
    templateUrl: './ag-set-filter.component.html',
    styleUrls: ['./ag-set-filter.component.scss']
})

export class AgSetFilterComponent implements OnInit {

    @Input() public name: string;
    @Input() public valueField = 'id';
    @Input() public filterTitle: string = '';
    @Output() changed = new EventEmitter();
    @Input() public options: any[] = [];

    public selectedValues: any[] = [];
    constructor() { }
    ngOnInit() {

    }

    public getValue(option: any) {
        return (this.valueField === 'name') ? option.name : option.id;
    }

    public valueChanged($event: any) {
        const value = $event.currentTarget.value;
        if ($event.currentTarget.checked) {
            this.selectedValues.push(value);
        } else {
            this.selectedValues = this.selectedValues.filter(v => v != value);
        }
    }

    public apply() {
        this.changed.emit(this.selectedValues);
    }

    public clear() {
        $('.' + this.name + '-set-checkbox').prop('checked', false)
        this.selectedValues = [];
        this.changed.emit(this.selectedValues);
    }
    public filterList(event: any) {
        let filter, cardRow, data, i, txtValue;
        filter = event.target.value.toUpperCase();
        cardRow = document.getElementsByClassName('filterdata') as HTMLCollectionOf<HTMLElement>;
        const cards = document.getElementsByClassName('AllRows') as HTMLCollectionOf<HTMLElement>;
        for (i = 0; i < cardRow.length; i++) {
            data = $(cardRow[i]).html();
            if (data) {
                txtValue = data;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    cards[i].style.display = '';
                } else {
                    cards[i].style.display = 'none';
                }
            }
        }
    }
}
