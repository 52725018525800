<div class="page">
    <div id="whitebox" class="loginForm">
        <div class="text-center">
            <img src="./assets/images/public-eye.svg" aria-roledescription="logo" class="loginForm__logo" />
        </div>
        <div class="loginForm__formContainer">
            <div id='mainContent' class='loginForm__mainContentContainer'>
                <div id='illustrationContainer' class='loginForm__illustrationContainer'>
                    <img src="assets/images/vector.png" class='loginForm__devImg' />
                </div>
                <form id="js-login" class='loginForm__form' [formGroup]="resetPasswordForm"
                    (ngSubmit)="requestPasswordResetEmail()">
                    <div class="row">
                        <div class="col">
                            <h4 class="text-center mb-3">Forgot Password?</h4>
                            <p class="text-muted mb-3">Enter the email address associated with your account and we will
                                send you a link to reset your password.</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-group">
                            <label class="form-label" for="email">Email</label>
                            <app-form-error-wrapper [control]="resetPasswordForm.controls['email']"
                                [controlName]="'Email'">
                                <input class="form-control" type="email" formControlName="email"
                                    placeholder="email@example.com" />
                            </app-form-error-wrapper>
                        </div>
                        <div class="invalid-feedback">No, you missed this one.</div>
                        <div class="help-block">We will email you the instructions</div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-12">
                            <button type="submit" class="shadow-0 btn btn-success btn-block btn-lg"
                                [disabled]="!resetPasswordForm.valid || loading"><i class="fal fa-spinner fa-spin"
                                    *ngIf="httpProcess"></i> Recover Password</button>
                            <a [routerLink]="'/login'" class="btn btn-outline-default btn-lg btn-block">Login</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="footer w-100">
        <div class="footer__companyInfo w-100 d-flex justify-content-center" id="copyright">
        </div>
    </div>
</div>

<div class="modal fade" id="alert-modal-md" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 9999">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{alert?.title}}
                    <small class="m-0 text-muted">
                        {{alert?.sub_title}}
                    </small>
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body" [innerHTML]="alert?.body"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary shadow-0" data-dismiss="modal"
                    (click)="closeModal()">{{alert?.close_text}}</button>
            </div>
        </div>
    </div>
</div>
