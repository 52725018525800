import { AdminEnum } from './../../shared/models/user.model';
import { ProblemTypeParent } from './../../portal/tickets/models/problem-types.modal';
import { FieldOption } from '@app/portal/settings/field-options/model/field-option.model';
import { CompanyTypes } from '@app/shared/models/company-types.enums';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssignedRole,
  Permission
} from '@app/portal/settings/RBACL/model/RBACL.model';
import { NavbarItem } from '@app/portal/sidebar/sidebar.component';
import { COUNTRIES } from '@app/shared/data/countries';
import { WorldCountriesJson } from '@app/shared/data/world';
import { Contract } from '@app/shared/models/company-settings.model';
import { CompaniesEnum } from '@app/shared/models/company.model';
import { FieldOptionTypes } from '@app/shared/models/field-option.model';
import {
  UserAccessGroupEnum,
  UserGroup
} from '@app/shared/models/groups.model';
import { NavigationMenu } from '@app/shared/models/navigation';
import { BaseAPIClass } from '../class/baseAPI.class';
import { AppData, AppSecurityContext } from '../model/app-data.model';
import { User } from '@app/shared/models/user.model';

@Injectable({ providedIn: 'root' })
export class ApplicationDataService extends BaseAPIClass {
  private readonly URL_FETCH_APP_DATA: string = 'auth/app_data';
  private appData: AppData = null;
  private userGroups: UserGroup[] = [];
  private fieldOptionStore: any = {};
  private RABCLStore: string[] = [];
  private allowedModuleIds: number[] = [];
  private countryFlagMap: any = {};

  public isAppDataAvailable() {
    return this.appData != null;
  }

  public async fetchAppData() {
    return this.getAsync<AppData>(this.URL_FETCH_APP_DATA).toPromise();
  }

  public async getAppData() {
    this.appData =
      this.appData == null ? await this.fetchAppData() : this.appData;
    if (Array.isArray(this.appData.app)) {
      this.appData = null;
    }
    await this.setUserGroups();
    return this.appData;
  }

  public async refreshAppData() {
    this.appData = await this.fetchAppData();
    await this.setUserGroups();
  }

  public async getFieldOptions() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.field_options;
  }

  public async getProblemTypes(loggedInUser: User) {
    if (this.appData == null) {
      await this.getAppData();
    }
    const problems = this.appData.app.problem_types;

    if (loggedInUser.company.type == CompanyTypes.TOW_TRUCK) {
      return problems
        .filter((problem: FieldOption) => problem.name == 'Others')
        .map((filterObj: ProblemTypeParent) => {
          filterObj.types = filterObj.types.filter(
            (type: FieldOption) => type.name == CompanyTypes.TOW_TRUCK
          );
          return filterObj;
        });
    } else if (loggedInUser.company.type == CompanyTypes.MUNICIPALITY) {
      problems.map((filterObj: ProblemTypeParent) => {
        if (filterObj.name == 'Others') {
          filterObj.types = filterObj.types.filter(
            (type: FieldOption) => type.name !== CompanyTypes.TOW_TRUCK
          );
        }
      });
      return problems;
    }
  }

  public buildFieldOptionStore(fieldOptionsTypes: any) {
    Object.keys(fieldOptionsTypes).forEach((key: any) => {
      if ('fieldOptions' in fieldOptionsTypes[key]) {
        for (const fieldOption of fieldOptionsTypes[key].fieldOptions) {
          this.fieldOptionStore[fieldOption.id] = fieldOption;
        }
      }
    });

    WorldCountriesJson.forEach(country => {
      this.countryFlagMap[country.name] = country.alpha2;
    });
  }

  public buildRBACLStore(assignedRole: AssignedRole) {
    Object.values(assignedRole.permissions).forEach(
      (permissions: Permission[]) => {
        permissions.forEach((permission: Permission) => {
          this.RABCLStore.push(permission.name.toLowerCase());
          if (this.allowedModuleIds.indexOf(permission.module_id) == -1) {
            this.allowedModuleIds.push(permission.module_id);
          }
        });
      }
    );
  }

  public getFieldOptionNameById(id: number) {
    return this.fieldOptionStore[id];
  }

  public async getRBACLRoles() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.rbacl.roles;
  }

  public getUsers() {
    return this.appData.app.users;
  }

  public getModules() {
    return this.appData.app.modules;
  }

  public async getLocations() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.locations;
  }

  public async getCountries() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.countries;
  }

  public async getSecurityContext() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.security_context;
  }

  public async getCompanyData() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.company;
  }

  public getDateFormats() {
    return [
      { id: 0, name: '31/12/2019' },
      { id: 1, name: '31/12/2019' },
      { id: 2, name: '31/Dec/2019' },
      { id: 3, name: '31-12-2019' },
      { id: 4, name: '12-31-2019' },
      { id: 5, name: '31-Dec-2019' }
    ];
  }

  public getTimeFormats() {
    return [
      { id: 0, name: '11:50 PM' },
      { id: 1, name: '23:50' }
    ];
  }

  public async getStats() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.stats;
  }

  public async getAssignedPermissions() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.assigned_rbacl.permissions;
  }

  public userBelongsToGroup(groupId: number) {
    return this.userGroups
      .map(userGroup => userGroup.group_id)
      .includes(groupId);
  }

  public async getEnvironments() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.field_options[28].fieldOptions;
  }

  public spoken_language() {
    return [
      { id: 0, name: 'Abkhazian' },
      { id: 1, name: 'Afar' },
      { id: 2, name: 'Afrikaans' },
      { id: 3, name: 'Akan' },
      { id: 4, name: 'Albanian' },
      { id: 5, name: 'Amharic' },
      { id: 6, name: 'Arabic' },
      { id: 7, name: 'Aragonese' },
      { id: 8, name: 'Armenian' },
      { id: 9, name: 'Assamese' },
      { id: 10, name: 'Avaric' },
      { id: 11, name: 'Avestan' },
      { id: 12, name: 'Aymara' },
      { id: 13, name: 'Azerbaijani' },
      { id: 14, name: 'Bambara' },
      { id: 15, name: 'Bashkir' },
      { id: 16, name: 'Basque' },
      { id: 17, name: 'Belarusian' },
      { id: 18, name: 'Bengali' },
      { id: 19, name: 'Bihari languages' },
      { id: 20, name: 'Bislama' },
      { id: 21, name: 'Bokm' },
      { id: 22, name: 'Bosnian' },
      { id: 23, name: 'Breton' },
      { id: 24, name: 'Bulgarian' },
      { id: 25, name: 'Burmese' },
      { id: 26, name: 'Catalan' },
      { id: 27, name: 'Central Khmer' },
      { id: 28, name: 'Chamorro' },
      { id: 29, name: 'Chechen' },
      { id: 30, name: 'Chinese' },
      { id: 31, name: 'Chuvash' },
      { id: 32, name: 'Cornish' },
      { id: 33, name: 'Corsican' },
      { id: 34, name: 'Cree' },
      { id: 35, name: 'Croatian' },
      { id: 36, name: 'Czech' },
      { id: 37, name: 'Danish' },
      { id: 38, name: 'Dutch' },
      { id: 39, name: 'Dzongkha' },
      { id: 40, name: 'English' },
      { id: 41, name: 'Esperanto' },
      { id: 42, name: 'Estonian' },
      { id: 43, name: 'Ewe' },
      { id: 44, name: 'Faroese' },
      { id: 45, name: 'Fijian' },
      { id: 46, name: 'Finnish' },
      { id: 47, name: 'French' },
      { id: 48, name: 'Fulah' },
      { id: 48, name: 'Gaelic' },
      { id: 49, name: 'Galician' },
      { id: 50, name: 'Ganda' },
      { id: 51, name: 'Georgian' },
      { id: 52, name: 'German' },
      { id: 53, name: 'Greek' }
    ];
  }

  public async setUserGroups() {
    if (this.userGroups.length == 0) {
      const securityContext: AppSecurityContext = await this.getSecurityContext();
      this.userGroups = securityContext.groups;
    }
  }

  public async processNavigation(
    navigation: NavbarItem[] = null,
    isSubNavigation = false
  ) {
    if (this.appData == null) {
      await this.getAppData();
    }
    const disabledModule: number[] = this.getDisbaledModules();
    if (navigation == null) {
      navigation = NavigationMenu.filter(
        (menu: NavbarItem) => !disabledModule.includes(menu.id)
      );
    }

    let topLevelMenu: NavbarItem[] = [];
    for (let menu of navigation) {
      if (this.isByPassableModule(menu)) {
        if (menu.hasOwnProperty('isDBOnlyModule') && !this.isDB()) {
          continue;
        }
        if (menu.subMenu != null) {
          const subMenu = await this.processNavigation(menu.subMenu, true);
          if (subMenu.length > 0) {
            menu.subMenu = subMenu;
            topLevelMenu.push(menu);
          }
        } else {
          topLevelMenu.push(menu);
        }
      }
    }
    return topLevelMenu;
  }

  // If Admin is logged in, do not check any permission. Or Its a freely available module.
  // A Freely available module will have an id >= 1000
  private isByPassableModule(menu: NavbarItem) {
    let pass = true;
    if ('accessLevel' in menu) {
      const accessLevel: number[] = menu.accessLevel;
      if (accessLevel.length) {
        pass = false;
        for (let i = 0; i < accessLevel.length; i++) {
          if (this.getUserAllGroupId().includes(accessLevel[i])) {
            pass = true;
          }
        }
      }
    } else {
      pass = false;
    }
    return pass;
  }

  // 1: Check against all of the modules that are allowed for the industry type.
  // 2: Check against company's contract.
  // 3: Check against loggedin User's permissions;
  public hasAccessToModule(moduleId: number, isSubNavigation = false) {
    const allModuleIdsAllowedForCompanyType: number[] = this.getModules().map(
      module => module.id
    );

    const contractModules = this.getContractModules();
    const checkForContract = false;
    if (!isSubNavigation) {
      if (!allModuleIdsAllowedForCompanyType.includes(moduleId)) {
        return false;
      }

      if (!this.userCarriesNoRestrictions()) {
        if (!this.allowedModuleIds.includes(moduleId)) {
          return false;
        }
        if (checkForContract) {
          if (!contractModules.includes(moduleId)) return false;
        }
      }
    }
    return true;
  }

  public isModuleAllowed(moduleId: number) {
    return this.userCarriesNoRestrictions() || this.hasAccessToModule(moduleId);
  }

  public getValidationErrorsAsList(request: HttpErrorResponse) {
    const responseErrors = request.error;
    const errorList: string[] = [];
    Object.keys(responseErrors).forEach((key: string) => {
      if (Array.isArray(responseErrors[key])) {
        responseErrors[key].forEach((errorMessage: string) => {
          errorList.push(errorMessage);
        });
      } else {
        if (key != 'code') {
          errorList.push(responseErrors[key]);
        }
      }
    });
    return errorList;
  }

  public getServerError(request: HttpErrorResponse) {
    return [request.error.error];
  }

  public hasValidationErrors(request: HttpErrorResponse) {
    return request.status === 422;
  }

  public hasServerError(request: HttpErrorResponse) {
    return request.status === 500;
  }
  public hasToManyAttemptsError(request: HttpErrorResponse) {
    return request.status === 429;
  }
  public hasAuthenticationError(request: HttpErrorResponse) {
    return request.status === 401;
  }

  public hasForbiddenError(request: HttpErrorResponse) {
    return request.status === 403;
  }

  public getDefaultCurrency() {
    return this.appData.app?.company?.c_ob_set?.default_currency;
  }

  public getRequestErrors(request: HttpErrorResponse) {
    if (this.hasServerError(request)) {
      return this.getServerError(request);
    } else if (this.hasValidationErrors(request)) {
      return this.getValidationErrorsAsList(request);
    } else if (this.hasAuthenticationError(request)) {
      return this.getServerError(request);
    } else if (this.hasToManyAttemptsError) {
      return this.getServerError(request);
    } else if (this.hasForbiddenError(request)) {
      return this.getServerError(request);
    }
    return [];
  }

  public get permissions() {
    return this.RABCLStore;
  }

  public get roleId() {
    return this.appData.app.assigned_rbacl.role_id;
  }

  public getCountryName(countryId: number) {
    const country = COUNTRIES.filter(country => country.id === countryId);
    return country && country[0] ? country[0].name : '--';
  }

  public getCountryFlag(countryName: string) {
    return this.countryFlagMap[countryName] != null
      ? './assets/images/round-flags/' + countryName.toLowerCase() + '.svg'
      : '';
  }

  public async getFieldOptionsByTypeId(typeId: number) {
    const fieldOptionTypes: FieldOptionTypes = await this.getFieldOptions();
    return fieldOptionTypes[typeId].fieldOptions;
  }

  public getDesks() {
    return this.appData.app.desks;
  }

  public isAdmin() {
    return this.getUserAllGroupId().includes(UserAccessGroupEnum.Administrator);
  }

  public isCompanyOwner() {
    return this.getUserAllGroupId().includes(UserAccessGroupEnum.CompanyOwner);
  }

  public isContractor() {
    return this.getUserAllGroupId().includes(UserAccessGroupEnum.Contractor);
  }

  public async userCarriesNoRestrictions() {
    await this.setUserGroups();
    return this.isCompanyOwner() || this.isAdmin();
  }

  public getUserAllGroupId() {
    return this.userGroups.map(userGroup => userGroup.group_id);
  }

  public isDB() {
    return this.appData.app.company?.c_id == CompaniesEnum.DB;
  }

  public getDisbaledModules() {
    const jsonSettings: any = this.appData.app.company?.c_ob_set
      ?.json_settings_object;
    let disabledModules = [];
    if (jsonSettings != null && 'disabled_mods' in jsonSettings) {
      disabledModules = jsonSettings.disabled_mods;
    }
    return disabledModules;
  }

  public async getVendorCategories() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.vendor.categories;
  }

  public async getVendorGroups() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.vendor.groups;
  }

  public async getSuppliers() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.vendor.suppliers;
  }

  public async getVendorData() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app.vendor;
  }

  public async getOutlets() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.app?.vendor?.outlets;
  }

  public getCompanyActiveContractInformation() {
    return;
    const contracts: Contract[] = this.appData.app.company?.c_ob_set?.contracts;
    return contracts.find(contract => contract.status == 'Active');
  }

  public getContractModules() {
    const activeContract: Contract = this.getCompanyActiveContractInformation();
    let moduleIds: number[] = [];
    if (activeContract == null) return moduleIds;
    moduleIds = activeContract.modules.map(module => module.module_id);
    return moduleIds;
  }

  public getActiveDashboardId() {
    return this.appData.app.active_dashboard;
  }

  public isIndustryForex() {
    return this.appData.app.company.c_ob_set.industry == 'Forex';
  }

  public isIndustryPointOfSale() {
    return this.appData.app.company.c_ob_set.industry == 'Point Of Sale';
  }

  public isIndustryDB() {
    return this.appData.app.company.c_ob_set.industry == 'DB';
  }

  public isIndustryGeneral() {
    return this.appData.app.company.c_ob_set.industry == 'General';
  }
  public resetAppDataService() {
    this.userGroups = [];
  }

  public async getLoggedInUser() {
    if (this.appData == null) {
      await this.getAppData();
    }
    return this.appData.userId;
  }
}
