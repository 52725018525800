<div *ngIf="!appDataLoaded" style="margin-top: 300px;">
    <app-loading>Putting things together...</app-loading>
</div>
<div *ngIf="appDataLoaded && isPosScreen()">
    <router-outlet>
        <app-spinner></app-spinner>
    </router-outlet>
</div>
<div class="page-wrapper no-print" *ngIf="appDataLoaded && !isPosScreen()">
    <div class="page-inner">
        <!-- BEGIN Left Aside -->
        <aside class="page-sidebar">
            <div class="page-logo" style="overflow: visible;">
                <a routerLink="/portal/dashboard"
                    class="d-flex position-relative">
                    <img src="./assets/images/public-eye.svg" aria-roledescription="logo" class="img-fluid"/>
                </a>
                <div class="hidden-md-down dropdown-icon-menu position-relative" style="z-index: 10000; margin-left: 5px;">
                    <a href="javascript:;" class="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden"
                        title="Hide Navigation">
                        <i class="ni ni-menu"></i>
                    </a>
                </div>
            </div>
            <app-sidebar class="slimScrollDiv"></app-sidebar>
        </aside>
        <div class="page-content-wrapper">
            <app-header></app-header>
            <main id="js-page-content" role="main" class="page-content position-relative">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>
                <app-portal-footer [version]="appVersion"></app-portal-footer>
            </main>
            <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>
            <div class="modal fade" id="alert-modal-md" tabindex="-1" role="dialog" aria-hidden="true"
                style="z-index: 9999">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header position-relative" [ngClass]="{'bg-success': alert?.type === alertTypes.Success,
                                                        'bg-info': alert?.type === alertTypes.Info,
                                                        'bg-danger': alert?.type === alertTypes.Error,
                                                        'bg-default': alert?.type === null
                                                       }">
                            <h4 class="modal-title">
                                {{alert?.title}}
                                <small class="m-0 text-muted">
                                    {{alert?.sub_title}}
                                </small>
                            </h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" style="color: #fff"><i class="fal fa-times"></i></span>
                            </button>
                        </div>
                        <div class="modal-body" [innerHTML]="alert?.body"></div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default shadow-0 rounded-0"
                                data-dismiss="modal">{{alert?.close_text}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="error-form-modal" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 9999">
    <div class="modal-dialog modal-dialog-centered" role="document">

    </div>
</div>
<div class="modal" data-backdrop="false" tabindex="-1" role="dialog" id="internalServerError">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Report a Problem</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">&times;</button>
            </div>
            <div class="modal-body">
                <form action="#" [formGroup]="internalServerErrorForm">
                    <div class="shortForm mb-3">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <app-form-error-wrapper
                                    [control]="internalServerErrorForm.controls['email']"
                                    [controlName]="'email'">
                                    <label class="form-label" for="email">Email:
                                    </label>
                                    <input type="text" id="email" class="cfm form-control"
                                        placeholder="Email" formControlName="email"
                                        #autofocus />
                                </app-form-error-wrapper>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <app-form-error-wrapper [control]="internalServerErrorForm.controls['description']"
                                    [controlName]="'Message'">
                                    <label class="form-label" for="description">Message:</label>
                                    <textarea type="text" rows="5" id="description" class="cfm form-control"
                                        placeholder="Message" formControlName="description"></textarea>
                                </app-form-error-wrapper>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col text-right mt-3">
                        <div class="btn-group-sm" role="group">
                            <button type="button" class="btn btn-md btn-outline-default shadow-0"
                                (click)="closeModal()"> Cancel </button>
                            <button type="button" class="btn btn-success ml-2 shadow-0" (click)="save()"> Save </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="agent && appDataLoaded" style="color: white;
    background-color: #333;
    position: sticky;
    bottom: 0;
    margin-left: 270px;">
    <p class="p-3"> You are logged in as <i>{{agent?.first_name}}</i>, <a class="text-info" href="javascript:;"
            (click)="switchAccount()">switch back to your account.</a></p>
</div>
