import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/local-storage.service';
import { AuthenticationEvent } from './authentication.model';
import { Credentials, LoginPayload } from '../model/authentication.model';


@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    protected _credentials: Credentials | null;
    public onAuthenticationChange = new EventEmitter < AuthenticationEvent > ();
    protected URL_LOGIN = 'auth/login';
    protected OUTLET_ID_KEY = 'outlet-id';
    protected URL_LOGOUT = 'auth/logout';
    protected AUTHENTICATION_EVENT_LOGGED_IN = 'logged-in';
    protected AUTHENTICATION_EVENT_LOGGED_OUT = 'logged-out';
    protected AUTHENTICATION_CLIENT_EVENT_LOGGED_OUT = 'client-logged-out';
    protected AUTHENTICATION_CLIENT_EVENT_LOGGED_IN = 'client-logged-in';
    public readonly URL_CLIENT_LOGIN = 'auth/client-login';

    protected credentialsKey = 'credentials';
    protected tempCredentialsKey = 'temporary_credentials';
    public readonly STORAGE_KEY_PROFILE: string = 'ClientProfile';
    protected authType = 'User';


    constructor(
        protected httpClient: HttpClient,
        protected localStorageService: LocalStorageService) {
        const savedCredentials = this.localStorageService.getItem(this.credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }

    public login(payload: LoginPayload): Observable < Credentials > {
        const headers: {
            [key: string]: string
        } = {
            'no-redirect': 'true',
            'X-Forwarded-For':'127.0.0.1'
        };
        return this.httpClient.post(this.URL_LOGIN, payload, {
            headers: headers
        }).pipe(
            map((body: Credentials) => {
                this.setCredentials(body);
                return body;
            })
        );
    }

    public logout(): Observable < boolean > {
        return this.httpClient
            .post(this.URL_LOGOUT, {}, {
                headers: this.getAuthHeadersAsync()
            })
            .pipe(
                map(() => {
                    const authenticationEvent: AuthenticationEvent = {
                        status: this.AUTHENTICATION_EVENT_LOGGED_OUT,
                        payload: {}
                    };
                    this.onAuthenticationChange.emit(authenticationEvent);
                    this.setCredentials();
                    return true;
                })
            );
    }

    public Clientlogin(payload: LoginPayload): Observable < Credentials > {
        const headers: {
            [key: string]: string
        } = {
            'no-redirect': 'true'
        };
        return this.httpClient.post(this.URL_CLIENT_LOGIN, payload, {
            headers: headers
        }).pipe(
            map((body: Credentials) => {
                this.setCredentials(body);
                return body;
            })
        );
    }

    public Clientlogout(): Observable < boolean > {
        return this.httpClient
            .post(this.URL_LOGOUT, {}, {
                headers: this.getAuthHeadersAsync()
            })
            .pipe(
                map(() => {
                    const authenticationEvent: AuthenticationEvent = {
                        status: this.AUTHENTICATION_CLIENT_EVENT_LOGGED_OUT,
                        payload: {}
                    };
                    this.onAuthenticationChange.emit(authenticationEvent);
                    this.setCredentials();
                    return true;
                })
            );
    }

    public isAuthenticated(): boolean {
        return !!this.credentials;
    }

    get credentials(): Credentials | null {
        return this._credentials;
    }

    public getAccessToken(): string | null {
        return this.credentials ? this.credentials.token : null;
    }

    public setCredentials(credentials ? : Credentials) {
        this._credentials = credentials || null;
        if (credentials) {
            const authenticationEvent: AuthenticationEvent = {
                status: this.AUTHENTICATION_EVENT_LOGGED_IN,
                payload: this._credentials
            };
            this.onAuthenticationChange.emit(authenticationEvent);
            this.localStorageService.setItem(this.credentialsKey, JSON.stringify(credentials));
            this.setActiveOutletId(credentials.default_outlet);
        } else {
            this.localStorageService.clearItem(this.credentialsKey);
            this.localStorageService.clearItem(this.OUTLET_ID_KEY);
        }
    }

    public setTempCredentials(credentials ? : Credentials) {
        if (credentials) {
            const authenticationEvent: AuthenticationEvent = {
                status: this.AUTHENTICATION_EVENT_LOGGED_IN,
                payload: this._credentials
            };
            this.onAuthenticationChange.emit(authenticationEvent);
            this.localStorageService.setItem(this.credentialsKey, JSON.stringify(credentials));
            this.localStorageService.setItem(this.tempCredentialsKey , JSON.stringify(this._credentials))
        } else {
            const adminCredentials =  this.localStorageService.getItem(this.tempCredentialsKey);
            this.localStorageService.setItem(this.credentialsKey, adminCredentials);
            this.localStorageService.clearItem(this.tempCredentialsKey);
            this.localStorageService.clearItem(this.STORAGE_KEY_PROFILE);
        }
    }

    protected getAuthorizationHeader(token: string) {
        return `Bearer ${token}`;
    }

    public setActiveOutletId(outletId: number) {
        this.localStorageService.setItem(this.OUTLET_ID_KEY, outletId);
    }

    public getActiveOutletId() {
        const outletId = this.localStorageService.getItem(this.OUTLET_ID_KEY);
        if(outletId === null) {
            return 1;
        }
        return outletId.toString();
    }

    protected getAuthHeadersAsync() {
        let token = null;
        token = this.getAccessToken();
        const headers: { [key: string]: string } = {
            'no-redirect': 'true',
            'auth-type': this.getAuthType(),
            Authorization: token ? this.getAuthorizationHeader(token) : ''
        };
        return headers;
    }

    public getAuthType() {
        return this.authType;
    }
}
