import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BlankComponent } from '@app/shared/layouts/blank/blank.component';
import { SpinnerComponent } from '@app/shared/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonLoaderComponent } from '@app/shared/button-loader';
import { FormErrorWrapperComponent } from '@app/shared/form-error-wrapper/form-error-wrapper.component';
import { SidebarComponent } from '@app/portal/sidebar/sidebar.component';
import { HeaderComponent } from '@app/portal/header/header.component';
import { RequestErrorAlertComponent } from './request-error-alert/request-error-alert.component';
import { SubHeaderComponent } from '@app/portal/sub-header/sub-header.component';
import { NotFoundComponent } from '@app/authentication/404';
import { SignupComponent } from '@app/authentication/signup';
import { LoginComponent } from '@app/authentication/login';
import { ForgotPasswordComponent } from '@app/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@app/authentication/reset-password/reset-password.component';
import { CommonModule } from '@angular/common';
import { ModalLoaderComponent } from '@app/portal/modal-loader/modal-loader.component';
import { CommentsModule } from '@app/portal/comments/comments.module';
import { DataTableComponent } from '@app/portal/datatable/datatable.component';
import { DataTableRowComponent } from '@app/portal/datatable/datatable.row.component';
import { PeopleLookupComponent } from './components/people-lookup/people-lookup.component';
import { ConfirmDeleteDirective } from './directives/confirm-delete.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog-component/confirmation-dialog-component';
import { GenericFieldOptionsComponent } from './components/generic-field-options/generic-field-options.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ChangePasswordComponent } from '@app/portal/settings/users/change-password/change-password.component';
import { ModulesDropDownComponent } from './components/modules-drop-down/modules-drop-down.component';
import { UserDigestComponent } from './components/user-digest/user-digest.component';
import { FullComponent } from './layouts/full/full.component';
import { PortalFooterComponent } from '@app/portal/footer/footer.component';
import { AppSettingsModalComponent } from '@app/portal/app-settings-modal/app-settings-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { EmployeesDropDownComponent } from './components/employees-drop-down/employees-drop-down.component';
import { TextHolderComponent } from './components/text-holder/text-holder.component';
import { GenericLocationsDropDownComponent } from './components/generic-locations-drop-down/generic-locations-drop-down.component';
import { GenericDropDownComponent } from './components/generic-dropdown/generic-dropdown.component';
import { Select2Directive } from './directives/select2-directive';
import { ListDropdownComponent } from './components/list-dropdown/list-dropdown.component';
import { CountriesDropDownComponent } from './components/countries-drop-down/countries-drop-down.component';
import { NoRecordsComponent } from './components/no-records/no-records.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { DateRangePickerDirective } from './directives/date-range-picker.directive';
import { DatePickerDirective } from './directives/date-picker.directive';
import { InputMaskDirective } from './directives/input-mask.diretive';
import { DefaultCurrencyPipe } from './pipes/default-currency.pipe';
import { CanDirective } from './directives/can.directive';
import { GlobalSearchComponent } from '@app/portal/global-search/global-search-component/global-search.component';
import { UsersLookupComponent } from './components/users-lookup/users-lookup.component';
import { DynamicFilterComponent } from './components/dynamic-filter/dynamic-filter.component';
import { SummerNoteDirective } from './directives/summernote.directive';
import { CantDirective } from './directives/cant.directive';
import { ColorCustomFormlyField } from './custom-fields/color-custom-formly-field';
import { SummerNoteCustomFormlyField } from './custom-fields/summernote-custom-formly-field';
import { WrapperColFormly } from './custom-fields/wrapper-col-formly';
import { AddClassIfMobileDirective } from './directives/add-class-on-mobile.directive';
import { SumoSelectDirective } from './directives/sumoselect.directive';
import { GenericSumoFieldOptionsComponent } from './components/generic-sumo-field-options/generic-sumo-field-options.component';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { CountriesMultiDropDownComponent } from './components/countries-multi-drop-down/countries-multi-drop-down.component';
import { PopoverComponent } from './components/popover/popover.component';
import { LinkRendererComponent } from './renderers/link.renderer';
import { CountriesListCheckboxesFilterComponent } from './components/countries-list-checkboxes-filter/countries-list-checkboxes-filter.component';
import { MultipleDirective } from './directives/multiple.directive';
import { ShowRowsComponent } from './components/show-rows/show-rows.component';
import { UserPreferencesModule } from '@app/portal/user-preferences/user-preferences.module';
import { AgSetFilterComponent } from './components/ag-set-filter/ag-set-filter.component';
import { AssignedToFilterComponent } from './filters/assigned-to-filter.component';
import { AgLoadingComponent } from './components/ag-loading/ag-loading.component';
import { DashboardTimeFrameFiltersComponent } from './components/dashboard-time-frame-filters/dashboard-time-frame-filters.component';
import { CountriesDropdownFormArrayComponent } from './components/countries-dropdown-form-array/countries-dropdown-form-array.component';
import { GenericFieldOptionsFormArrayComponent } from './components/generic-field-options-form-array/generic-field-options-form-array.component';
import { EncryptValueRendererComponent } from './renderers/encrypt-value.renderer';
import { ShowEncryptedDataComponent } from './components/show-encrypted-data/show-encrypted-data.component';
import { EncryptedDataComponent } from './components/encrypted-data/encrypted-data.component';
import { Select2StructDirective } from './directives/select2struct.directive';
import { GetHelpComponent } from '@app/portal/sub-header/get-help/get-help.component';
import { CountriesSetFilterComponent } from './filters/countries-set-filter.component';
import { CountryRendererComponent } from './renderers/country.renderer';
import { IdCheckboxRendererComponent } from './renderers/id-checkbox-renderer';
import { DateAgoPipe } from './pipes/date-ago.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    CommentsModule,
    UserPreferencesModule
  ],
  declarations: [
    GlobalSearchComponent,
    SpinnerComponent,
    BlankComponent,
    FooterComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent,
    HeaderComponent,
    SidebarComponent,
    RequestErrorAlertComponent,
    SubHeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    SignupComponent,
    LoginComponent,
    ModalLoaderComponent,
    DataTableComponent,
    DataTableRowComponent,
    ChangePasswordComponent,
    DataTableComponent,
    DataTableRowComponent,
    PeopleLookupComponent,
    ConfirmDeleteDirective,
    Select2Directive,
    Select2StructDirective,
    ConfirmationDialogComponent,
    GenericFieldOptionsComponent,
    LoadingComponent,
    NoRecordsComponent,
    ModulesDropDownComponent,
    UserDigestComponent,
    FullComponent,
    ModulesDropDownComponent,
    PortalFooterComponent,
    AppSettingsModalComponent,
    EmployeesDropDownComponent,
    TextHolderComponent,
    GenericLocationsDropDownComponent,
    GenericDropDownComponent,
    ListDropdownComponent,
    CountriesDropDownComponent,
    ErrorListComponent,
    DateRangePickerDirective,
    DatePickerDirective,
    InputMaskDirective,
    DefaultCurrencyPipe,
    CanDirective,
    CantDirective,
    UsersLookupComponent,
    DynamicFilterComponent,
    SummerNoteDirective,
    ColorCustomFormlyField,
    SummerNoteCustomFormlyField,
    WrapperColFormly,
    AddClassIfMobileDirective,
    SumoSelectDirective,
    GenericSumoFieldOptionsComponent,
    CountriesMultiDropDownComponent,
    CountryFlagComponent,
    PopoverComponent,
    LinkRendererComponent,
    CountriesListCheckboxesFilterComponent,
    MultipleDirective,
    ShowRowsComponent,
    AgSetFilterComponent,
    AssignedToFilterComponent,
    AgLoadingComponent,
    DashboardTimeFrameFiltersComponent,
    CountriesDropdownFormArrayComponent,
    GenericFieldOptionsFormArrayComponent,
    EncryptValueRendererComponent,
    ShowEncryptedDataComponent,
    EncryptedDataComponent,
    GetHelpComponent,
    CountriesSetFilterComponent,
    CountryRendererComponent,
    IdCheckboxRendererComponent,
    DateAgoPipe
    //Widgets

  ],
  entryComponents: [
    ModalLoaderComponent,
    ChangePasswordComponent,
    PeopleLookupComponent,
    UsersLookupComponent,
    ConfirmationDialogComponent,
    AgSetFilterComponent,
    LinkRendererComponent,
    AssignedToFilterComponent,
    AgLoadingComponent,
    EncryptValueRendererComponent,
    ShowEncryptedDataComponent,
  ],
  exports: [
    ShowRowsComponent,
    LoadingComponent,
    SpinnerComponent,
    BlankComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent,
    HeaderComponent,
    SidebarComponent,
    RequestErrorAlertComponent,
    SubHeaderComponent,
    ModalLoaderComponent,
    DataTableComponent,
    DataTableRowComponent,
    ChangePasswordComponent,
    PeopleLookupComponent,
    ConfirmDeleteDirective,
    Select2Directive,
    Select2StructDirective,
    ConfirmationDialogComponent,
    SpinnerComponent,
    UserDigestComponent,
    GenericFieldOptionsComponent,
    ModulesDropDownComponent,
    PortalFooterComponent,
    AppSettingsModalComponent,
    FooterComponent,
    EmployeesDropDownComponent,
    TextHolderComponent,
    GenericLocationsDropDownComponent,
    GenericDropDownComponent,
    ListDropdownComponent,
    CountriesDropDownComponent,
    NoRecordsComponent,
    ErrorListComponent,
    DateRangePickerDirective,
    DatePickerDirective,
    InputMaskDirective,
    DefaultCurrencyPipe,
    CanDirective,
    UsersLookupComponent,
    SummerNoteDirective,
    CantDirective,
    ColorCustomFormlyField,
    SummerNoteCustomFormlyField,
    WrapperColFormly,
    AddClassIfMobileDirective,
    SumoSelectDirective,
    GenericSumoFieldOptionsComponent,
    CountriesMultiDropDownComponent,
    CountryFlagComponent,
    PopoverComponent,
    LinkRendererComponent,
    CountriesListCheckboxesFilterComponent,
    MultipleDirective,
    AgSetFilterComponent,
    AssignedToFilterComponent,
    AgLoadingComponent,
    CountriesDropdownFormArrayComponent,
    GenericFieldOptionsFormArrayComponent,
    EncryptValueRendererComponent,
    ShowEncryptedDataComponent,
    DateAgoPipe,
    //Widgets
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DatePickerDirective
  ]
})

export class SharedModule { }
