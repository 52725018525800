
export interface RealTimeNotificationEvent {
    receiverIds: number[];
    notification: string;
}

export interface Notification {
    type: number;
    message: string;
    data: any;
}


export enum NotificationTypeEnum
{
    Login        = 698,
    Deposit      = 699,
    Withdrawal   = 700,
}
