import {
    Injectable
} from '@angular/core';
import {
    CanActivate,
    Router
} from '@angular/router';
import {
    ApplicationDataService
} from '@app/core/service/application-data.service';

@Injectable()
export class AdminOnlyGaurd implements CanActivate {
    constructor(
        private router: Router,
        private applicationDataService: ApplicationDataService) {}

    async canActivate() {
        return await this.evaluatePermissions();
    }

    async canLoad() {
        return await this.evaluatePermissions();
    }

    private evaluatePermissions = async () => {
        if (this.applicationDataService.isAppDataAvailable()) {
            return this.doPermissionEvaluation();
        } else {
            await this.applicationDataService.refreshAppData()
            return this.doPermissionEvaluation();
        }
    }

    private doPermissionEvaluation () {
        this.applicationDataService.setUserGroups();
        const admin = this.applicationDataService.isAdmin();
        if (admin) {
            return true;
        } else {
            this.router.navigate(['/portal/page-not-accessible'], {
                replaceUrl: true
            });
        }
    }
}
