export interface Module {
    id: number,
    name: string,
    status: string,
};

export enum ModuleEnum {
     Acl               = 1,
     Accounts          = 2,
     ActivityLog       = 3,
     Application       = 4,
     Calendar          = 5,
     Clients           = 6,
     Comments          = 7,
     Documents         = 8,
     Emails            = 9,
     EmailTemplates    = 10,
     Groups            = 11,
     People            = 12,
     Products          = 13,
     Services          = 14,
     Settings          = 15,
     Tasks             = 16,
     Users             = 17,
     SMTPConnections   = 18,
     SMTPs             = 19,
     TransactionTypes   = 20,
     Transactions      = 21,
     Suppliers         = 22,
     Workflows          = 23,
     Customers         = 24,
     Invoices          = 25,
     Tickets           = 26,
     Companies         = 28,
     Payments          = 29,
     Leads             = 30,
     LeadComment       = 31,
     PromoCodes        = 32,
     MonetaryTransactions   = 33,
     FinancialTransactionExtraInfo = 34,
     TradingAccounts = 35,
     Brands            = 36,
     Roles             = 37,
     Permissions       = 38,
     Profiles          = 39,
     LeadMarketingDetail = 40,
     AccountPersonalInformation = 41,
     AccountTradingExperience = 42,
     AccountContactInformation = 43,
     AccountLegalInformation = 44,
     AccountLeadConversion = 45,
     AccountMarketingDetail = 46,
     TradingPlatforms = 47,
     TradingAccountPlatform = 48,
     UserPreferences = 49,
     TicketFailedDeposit = 50,
     TicketExtraInformation = 51,
     TicketInternalTransfer = 52,
     TicketLeverageRequest = 53,
     TicketWithdrawalRequest = 54,
     AccountTypes = 55,
     Affiliates = 56,
     Areas = 57,
     Assets = 58,
     AssignAssets = 59,
     AssignEmployees = 60,
     AssignProjects = 61,
     BankAccount = 62,
     Currencies = 63,
     EmployeesPolicies = 64,
     Employments = 65,
     EmsAttendance = 66,
     Compensations = 67,
     EmsEmployee = 68,
     RBACL = 69,
     EventUsers = 70,
     Expenses = 71,
     Firewalls = 72,
     HeartBeats = 73,
     Holidays = 74,
     Medias = 75,
     Notifications = 76,
     FieldOptions = 77,
     PoliciesCategories = 78,
     Policies = 79,
     Projects = 80,
     SmsConfigurations = 81,
     TimeOffTypes = 82,
     TimeOff = 83,
     Tools = 84,
     UserGroup = 85,
     WorkflowTodos = 86,
     CalendarEvents = 87,
     CalendarUser = 88,
     WireTransfer = 89,
     Campaign = 90,
     Help = 99,
     UsersDevices = 100,
     UserJobs = 101
}

