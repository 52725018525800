import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
    @Input() public height: number|string;
    constructor() { }

    ngOnInit() {
        this.style();
    }
    public style() {
        if (!this.height) {
            this.height = 50;
        }
        this.height = this.height + 'px';
    }

}
