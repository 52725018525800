<header class="page-header fixed-header" role="banner">
    <!-- we need this logo when user switches to nav-function-top -->
    <div class="page-logo">
        <a href="#" class="page-logo-link d-flex align-items-center position-relative" data-toggle="modal"
            data-target="#modal-shortcut">
            <img alt="Technologies" aria-roledescription="logo" src="./assets/images/crm-1.png" />
            <span class="page-logo-text mr-1">POS</span>
            <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
            <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
        </a>
    </div>
    <!-- DOC: nav menu layout change shortcut -->

    <!-- DOC: mobile button appears during mobile width -->
    <div class="hidden-lg-up">
        <a href="javascript:;" class="header-btn btn " data-action="toggle" data-class="mobile-nav-on">
            <i class="ni ni-menu"></i>
        </a>
        <a id="btn-srch" href="javascript:;" class="header-btn btn" (click)="fading()" style="width: 45px !important;">
            <i class="fal fa-search"></i>
        </a>
    </div>
    <div class="ml-auto d-flex">
        <div class="hidden-md-down" *ngIf="isPointOfSale()">
            <a routerLink="/portal/vendor/sales-screen/simple" class="header-icon"
                title="Point Of Sale Screen">
                <i class="ni ni-screen-desktop"></i>
            </a>
        </div>
        <div class="hidden-md-down">
            <a href="#" class="header-icon" data-toggle="modal" data-target=".js-modal-settings"
                title="Layout Settings">
                <i class="fal fa-cog"></i>
            </a>
        </div>
        <div class="hidden-md-down" *ngIf="isPointOfSale()">
            <a href="#" class="header-icon" data-toggle="modal" data-target=".outlet-switcher-modal" title="Switch Outlet">
                <i class="ni ni-home"></i>
            </a>
        </div>
        <div>
            <div>
                <a href="#" class="header-icon" data-toggle="dropdown" title="My Apps">
                    <i class="fal fa-cube"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-animated w-auto h-auto">
                    <div
                        class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top">
                        <h4 class="m-0 text-center color-white">
                            Quick Shortcut
                            <small class="mb-0 opacity-80">User Applications & Addons</small>
                        </h4>
                    </div>
                    <div class="custom-scroll h-100">
                        <ul class="app-list">
                            <li [appIfHasPermssion]="'Leads.List'" *ngIf="isForex()">
                                <a [routerLink]="['/portal/leads']" class="app-list-item hover-white">
                                    <span class="icon-stack">
                                        <i class="base-7 icon-stack-3x color-info-500"></i>
                                        <i class="base-7 icon-stack-2x color-info-700"></i>
                                        <i class="fal fa-users  icon-stack-1x text-white fs-lg"></i>
                                    </span>
                                    <span class="app-list-name">
                                        Leads
                                    </span>
                                </a>
                            </li>
                            <li [appIfHasPermssion]="'Accounts.List'" *ngIf="isForex()">
                                <a [routerLink]="['/portal/clients']" class="app-list-item hover-white">
                                    <span class="icon-stack">
                                        <i class="base-2 icon-stack-3x color-primary-400"></i>
                                        <i class="base-10 text-white icon-stack-1x"></i>
                                        <i class="ni md-profile color-primary-800 icon-stack-2x"></i>
                                    </span>
                                    <span class="app-list-name">
                                        Clients
                                    </span>
                                </a>
                            </li>
                            <li [appIfHasPermssion]="'TradingPlatforms.List'" *ngIf="isForex()">
                                <a [routerLink]="['/portal/settings/trading-platform']"
                                    class="app-list-item hover-white">
                                    <span class="icon-stack">
                                        <i class="base-9 icon-stack-3x color-success-400"></i>
                                        <i class="base-2 icon-stack-2x color-success-500"></i>
                                        <i class="ni ni-list icon-stack-1x text-white"></i>
                                    </span>
                                    <span class="app-list-name">
                                        Trading Platform Account
                                    </span>
                                </a>
                            </li>
                            <li [appIfHasPermssion]="'Tickets.List'">
                                <a [routerLink]="['/portal/tickets/list']" class="app-list-item hover-white">
                                    <span class="icon-stack">
                                        <i class="base-7 icon-stack-3x color-info-500"></i>
                                        <i class="base-7 icon-stack-2x color-info-700"></i>
                                        <i class="fal fa-dot-circle icon-stack-1x text-white fs-lg"></i>
                                    </span>
                                    <span class="app-list-name">
                                        Reports
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- activate app search icon (mobile) -->
        <div>
            <a href="#" data-toggle="dropdown"
                class="header-icon d-flex align-items-center justify-content-center ml-2">
                <img src="assets\images\user1.png" class="profile-image rounded-circle" />
                <span
                    class="ml-1 mr-1 text-truncate text-truncate-header hidden-xs-down">{{this.user?.first_name}}</span>
                <i class="ni ni-chevron-down hidden-xs-down"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
                <div class="dropdown-header d-flex flex-row py-4 rounded-top">
                    <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                        <span class="mr-2">
                            <img src="assets\images\user1.png" class="rounded-circle profile-image" />
                        </span>
                        <div class="info-card-text">
                            <div class="fs-lg text-truncate text-truncate-lg color-fusion-300">
                                <!-- {{this.user?.company.name}} -->
                            </div>
                            <span class="text-truncate text-truncate-md color-fusion-50">
                                {{this.user?.first_name}} {{this.user?.last_name}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="dropdown-divider m-0"></div>
                <a routerLink="/portal/settings/profile" class="dropdown-item">
                    <span data-i18n="drpdwn.settings">Profile</span>
                </a>
                <a class="dropdown-item" (click)="showPreferences()">
                    <span data-i18n="drpdwn.settings">Preferences</span>
                </a>
                <a routerLink="/portal/settings/reset-profile-password" class="dropdown-item">
                    <span data-i18n="drpdwn.settings">Change Password</span>
                </a>
                <div class="dropdown-divider m-0"></div>
                <a href="#" class="dropdown-item" data-action="app-fullscreen">
                    <span data-i18n="drpdwn.fullscreen">Fullscreen</span>
                    <i class="float-right text-muted fw-n">F11</i>
                </a>
                <div class="dropdown-multilevel dropdown-multilevel-left">
                    <div class="dropdown-item">
                        Language
                    </div>
                    <div class="dropdown-menu">
                        <button #locale  value="en" class="dropdown-item active">English
                            (US)</button>
                        <!-- <button #locale2  value="es" class="dropdown-item">Spanish</button> -->

                    </div>
                </div>
                <div class="dropdown-divider m-0"></div>
                <a class="dropdown-item fw-500 pt-3 pb-3" href="javascript:;" (click)="logoutAsync()">Logout
                    <span class="float-right fw-n">...</span>
                </a>
            </div>
        </div>
    </div>
    <div id="globalSearch" class="globalSearchDiv">
        <app-global-search id="global-srch" style="left: 0 !important;"></app-global-search>
    </div>
</header>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="mPreferences" >
    <div class="modal-dialog modal-dialog-right" style="max-width: 400px !important;">
        <div class="modal-content">
            <div class="dropdown-header bg-dark d-flex justify-content-center align-items-center w-100 p-1">
                <h4 class="text-center color-white mt-3">
                    MANAGE
                    <small>Your Preferences</small>
                </h4>
                <i class="fal fa-times close text-white position-absolute pos-top pos-right p-2 m-1 mr-2" (click)="close()"></i>
            </div>
            <div class="modal-body p-0">
                <div class="card shadow-0 border-0">
                    <div class="card-body">
                        <app-manage-preferences (emitData)="handleEvent($event)">
                        </app-manage-preferences>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

