<div id="mainDiv">
    <input type="text" name="txt" id="txt" class="form-control txt" (focus)="style($event)" placeholder="Type to search something globally in the system..." (input)="search($event)" autocomplete="off">
    <div id="dropDown" class="dropDown  flex-1 h-100" style="max-height: 550px;  background-color: white; margin-top: 4px; border-radius: 4px;">
        <div class="d-flex border border-left-0 border-right-0 border-top-0 p-1">
            <label for="footer" class="mt-2 ml-4"> <i class="fal fa-search"></i> Advanced Issue Searched </label>
        </div>
        <div class="container-fluid my-2 custom-scroll" style="overflow-y: scroll; max-height: 550px; padding-bottom: 78px !important;">
            <div class="row">
                <div class="col-12" *ngIf="!searching">
                    <div>
                        <ul class="list-group">
                            <a (click)="navigateTo('list-users')" class="list-group-item list-group-item-action border border-left-0 border-right-0 border-bottom-0 border-top-0" *ngIf="loggedInUserTypeId == 1">
                                <i class="fal fa-user"></i> Users
                                <span class="float-right text-muted">Go to Module Listing</span></a>
                                <a (click)="navigateTo('settings/users')" class="list-group-item list-group-item-action border border-left-0 border-right-0 border-bottom-0 border-top-0" *ngIf="loggedInUserTypeId == 1 || 2">
                                    <i class="fal fa-user"></i> Employees
                                    <span class="float-right text-muted">Go to Module Listing</span></a>
                            <a (click)="navigateTo('tickets/list')" class="list-group-item list-group-item-action border border-left-0 border-right-0 border-bottom-0 border-top-0">
                                <i class="fal fa-exclamation-triangle"></i> Problems
                                <span class="float-right text-muted">Go to Module Listing</span></a>
                        </ul>
                    </div>
                </div>
                <div class="col-12">
                    <div class="text-center" *ngIf="moduleResultNotFound">
                        <label for="" class=" text-danger">We couldn't find anything matching your search.</label>
                    </div>
                    <div class="text-center" *ngIf="loading">
                        <div class="spinner-border " role="status">
                            <span class="sr-only ">Loading...</span>
                        </div>
                    </div>
                    <div *ngFor="let module of getListOfModules() let i=index">
                        <div class="search-block" *ngIf="hasResults(module)">
                            <div class="w-100 border border-left-0 border-right-0 border-top-0 customStyle font-weight-bold">
                                <i [ngStyle]="{color: getModuleIconColor(module)}" [ngClass]="getModuleIcon(module)"></i>
                                <span class="font-weight-bold"></span> {{getModuleName(module)}}
                            </div>
                        </div>
                        <ul class="list-group">
                            <div *ngFor="let result of getResults(module)">
                                <a href="javascript:;" (click)="navigateToResult(result)" class="list-group-item list-group-item-action border border-left-0 border-right-0 border-bottom-0 border-top-0">
                                    {{result.text}}
                                    <span class="float-right text-muted">{{result.description}}</span></a>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex border border-left-0 border-right-0 border-bottom-0 p-1 dropDownFooter" style="position: sticky;">
            <label for="footer" class="mt-2 ml-4"> Go To: </label>
            <button class="btn btn-light btn-xs shadow-0 ml-2" (click)="navigateTo('list-users')" *ngIf="loggedInUserTypeId == 1">Users</button>
            <button class="btn btn-light btn-xs shadow-0 ml-2" (click)="navigateTo('settings/users')" *ngIf="loggedInUserTypeId == 1 || 2">Employees</button>
            <button class="btn btn-light btn-xs shadow-0 ml-2" (click)="navigateTo('tickets/list')">Problems</button>
        </div>
    </div>
</div>
