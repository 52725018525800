export interface Groups {
    id: number;
    group_name: string;
    is_default: string;
    created_at: Date;
    updated_at: Date;
}

export interface UserGroup {
    group_id: number;
}

export interface UserGroupsResponse {
    groups: UserGroup[];
}

export enum UserAccessGroupEnum {
    Administrator = 1,  // Super Admin
    CompanyOwner = 2, // Default user when company is created
    Employee = 3,
    User = 4,   // Mobile User
    Contractor = 5
}