import { CompanyTypes } from '@app/shared/models/company-types.enums';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../settings/profile/profile.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { UserAccessGroupEnum } from '@app/shared/models/groups.model';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { NavItemType } from '@app/shared/models/navigation';
import { ModuleEnum } from '@app/shared/models/module.model';
import { CompanySettingsObject as LoggedInCompany} from '@app/core/model/app-data.model';
import { User, UserTypeEnumIds } from '@app/shared/models/user.model';
import { SettingsSubMenu } from '@app/shared/models/settings-sidebar.model';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent extends AbstractBaseComponent implements OnInit, OnDestroy {
    public NavItemType: NavItemType;
    public NavbarItems: NavbarItem[] = [];
    public menuReady = false;
    public currentLink: string = null;
    constructor(
        protected profileService: ProfileService,
        protected applicationDataService: ApplicationDataService,

    private router: Router) {
        super();
    }

    async ngOnInit() {
        this.currentLink = this.router.url;
        this.employee = await this.profileService.getProfileAsync();
        this.loadSidebarFor();
        this.ready = true;
    }
    ngOnDestroy(): void {
        this.NavbarItems = [];
        this.applicationDataService.resetAppDataService();
    }
    public gotoPage(navbarItem: NavbarItem) {
        if (navbarItem.link !== 'javascript:;') {
            if (navbarItem.link.includes('generic')) {
                this.redirectTo(navbarItem.link);
                this.currentLink = navbarItem.link;
            } else {
                this.router.navigateByUrl(navbarItem.link);
                this.currentLink = navbarItem.link;
            }
        }
    }

    async loadSidebarFor(navbarItem?: NavbarItem) {
        if(this.applicationDataService.isAppDataAvailable()) {
            let navigation: NavbarItem[] = await this.loadRelaventSideBar();
            this.NavbarItems = await this.applicationDataService.processNavigation(navigation);
            this.buildNavigationMenu();
        }
    }

    private buildNavigationMenu() {
        const navSpeed = 500;
        const navClosedSign =  'fal fa-angle-down';
        const navOpenedSign = 'fal fa-angle-up';
        const navInitalized = 'js-nav-built';
        setTimeout(function() {
            (<any>$)('#js-nav-menu').navigation({
                accordion : true,
                speed : navSpeed,
                closedSign : '<em class="' + navClosedSign + '"></em>',
                openedSign : '<em class="' + navOpenedSign + '"></em>',
                initClass: navInitalized
            });
        }, 400);

        setTimeout(()=> {
            this.activateSelectedMenu();
        }, 400);
    }

    public get navType(): typeof NavItemType {
        return NavItemType;
    }

    private redirectTo(uri: string){
        this.router.navigateByUrl('/').then(() =>
        this.router.navigate([uri]));
    }

    private activateSelectedMenu() {
        this.NavbarItems.forEach((navbarItem: NavbarItem)=> {
            if(navbarItem?.subMenu?.length) {
                const index = navbarItem.subMenu.findIndex((item: NavbarItem)=> item.link == this.currentLink);
                if(index > -1) {
                    $("#level-1-"+ navbarItem.id).css({display: 'block'}).parent().addClass("open");
                }
            }
        });
    }

    public async loadRelaventSideBar() {
        const loggedInUser:User = await this.applicationDataService.getLoggedInUser();
        let adminSideBar: NavbarItem[];

        adminSideBar = [
            {
                id: 1,
                title: 'Dashboard',
                link: '/portal/dashboard',
                icon: 'fal fa-chart-area',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator, 
                            UserAccessGroupEnum.Employee,
                            UserAccessGroupEnum.CompanyOwner,
                        ]
            },
            {
                id: ModuleEnum.Companies,
                title: 'Municipalities',
                link: 'javascript:;',
                icon: 'fal fa-building',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator,
                    UserAccessGroupEnum.Employee,
                    UserAccessGroupEnum.CompanyOwner,
                ],
                subMenu: [
                    {
                        id: 50,
                        title: 'Municipalities',
                        link: '/portal/companies/list-municipalities',
                        icon: 'fal fa-building',
                        type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator],
                    },
                    {
                        title: 'Municipality',
                        link: `/portal/companies/view/${loggedInUser.company.id}`,
                        icon: 'fal fa-building',
                        type: NavItemType.Menu,
                        accessLevel: [UserAccessGroupEnum.Employee,
                            UserAccessGroupEnum.CompanyOwner,
                        ],
                    },
                    {
                        id: ModuleEnum.Companies,   // check on demand
                        title: 'Tow Truck',
                        link: `/portal/companies/view/${loggedInUser.company.id}`,
                        icon: 'fal fa-truck',
                        type: NavItemType.Menu
            
                    },
                    {
                        id: 60,
                        title: 'Sub Municipalities',
                        link: '/portal/companies/list-sub',
                        icon: 'fal fa-building',
                        type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator,
                    UserAccessGroupEnum.Employee,
                    UserAccessGroupEnum.CompanyOwner,
                ],

                    },
                ]
            },
            {
                id: ModuleEnum.Companies,
                title: 'Tow Trucks',
                link: '/portal/companies/tow-trucks',
                icon: 'fal fa-truck',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator
                ],

            },
            {
                id: ModuleEnum.Users,
                title: 'Users',
                link: 'javascript:;',
                icon: 'fal fa-users',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator,
                    UserAccessGroupEnum.Employee,
                    UserAccessGroupEnum.CompanyOwner,  
                ],
                subMenu: [
                    {
                        title: 'Employees',
                        link: '/portal/settings/users',
                        icon: 'fal fa-building',
                        type: NavItemType.Menu,
                        accessLevel: [UserAccessGroupEnum.Administrator,
                            UserAccessGroupEnum.Employee,
                            UserAccessGroupEnum.CompanyOwner,  
                        ],
                    },
                    {
                        id: ModuleEnum.Users,
                        title: 'Users',
                        link: '/portal/list-users',
                        icon: 'fal fa-cogs',
                        type: NavItemType.Menu,
                        accessLevel: [UserAccessGroupEnum.Administrator],
                    },
                ]
            },
            {
                id: ModuleEnum.UserJobs,
                title: 'Jobs',
                link: '/portal/user-jobs',
                icon: 'fal fa-tasks',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator]
            },
            {
                id: ModuleEnum.Emails,
                title: 'Emails',
                link: 'javascript:;',
                icon: 'fal fa-envelope',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator,
                    UserAccessGroupEnum.Employee,
                    UserAccessGroupEnum.CompanyOwner,  
                ],
                subMenu: [{
                    id: ModuleEnum.Emails,
                    title: 'Emails',
                    link: '/portal/emails/lists',
                    icon: 'fal fa-envelope',
                    accessLevel: [UserAccessGroupEnum.Administrator,
                        UserAccessGroupEnum.Employee,
                        UserAccessGroupEnum.CompanyOwner,  
                    ],
                },
                {
                    id: ModuleEnum.EmailTemplates,
                    title: 'Email Templates',
                    link: '/portal/settings/email-templates',
                    icon: 'fal fa-email',
                    accessLevel: [UserAccessGroupEnum.Administrator,
                        UserAccessGroupEnum.Employee,
                        UserAccessGroupEnum.CompanyOwner,  
                    ],
                },
                ]
            },
            {
                id: ModuleEnum.Tickets,
                title: 'Problems Reported',
                link: '/portal/tickets/list',
                icon: 'fal fa-exclamation-triangle',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator,
                    UserAccessGroupEnum.Employee,
                    UserAccessGroupEnum.CompanyOwner,  
                    UserAccessGroupEnum.Contractor,
                ],
            },
            {
                id: ModuleEnum.Calendar,
                title: 'Events',
                link: '/portal/calendar/list-calendar',
                icon: 'fal fa-calendar',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator,
                    UserAccessGroupEnum.Employee,
                    UserAccessGroupEnum.CompanyOwner,  
                ],
            },
            {
                id: ModuleEnum.Notifications,
                title: 'Mobile App Notifications',
                link: '/portal/notifications/list',
                icon: 'fal fa-bell',
                type: NavItemType.Menu,
                accessLevel: [UserAccessGroupEnum.Administrator
                ],
            },
            {
                id: 1002,
                title: 'Settings',
                link: 'javascript:;',
                icon: 'fal fa-cog',
                type: NavItemType.Menu,
                subMenu: SettingsSubMenu,
                accessLevel: [UserAccessGroupEnum.Administrator]
            },
        ];
        return adminSideBar;
    }

}

export interface NavbarItem {
  id?: number;
  title?: string;
  link?: string;
  icon?: string;
  subMenu?: NavbarItem[];
  type?: NavItemType;
  isDBOnlyModule?: boolean;
  accessLevel?: UserAccessGroupEnum[];
}
