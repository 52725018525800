<div class="modal-header" *ngIf="isModal">
    <h4 class="modal-title">Reset Password</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
</div>
<div class="modal-body" *ngIf="ready">
    <form action="#" [formGroup]="changePasswordForm">
        <div class="form-group row">
            <div class="col-12">
                <label class="form-label" for="input-group-sm-size">New Password:<span
                        style="color: red;">*</span></label>

                <app-form-error-wrapper [control]="changePasswordForm.controls['password']" [controlName]="'Password'">
                    <div class="input-group input-group-sm">
                        <input id="input-group-sm-size password" formControlName="password" type="{{Type}}"
                            class="form-control" placeholder="New Password" aria-describedby="input-group-sm-size"
                            #autofocus>
                        <div class="input-group-append">
                            <button (click)="generatePassword(8)"
                                class="btn btn-outline-default waves-effect waves-themed" type="button"
                                id="button-addon2">Generate</button>
                        </div>
                    </div>
                </app-form-error-wrapper>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12">
                <div class="checkbox">
                    <label><input class="mr-1" type="checkbox" value="" (click)="action()"> Show Password</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col text-right mt-2">
                <button type="button" class="btn shadow-0 btn-md btn-outline-default"
                    (click)="close()">Cancel</button>
                <button type="button" [class.btnSpinner]="true" class="btn btn-success ml-2 shadow-0"
                    [disabled]="!changePasswordForm.valid || httpProcess" (click)="save()"><i
                        class="fal fa-spinner fa-spin" *ngIf="httpProcess"></i> Save</button>
            </div>
        </div>
    </form>
</div>
