<div style="display: inline-block; width: 213px;">
    <div class="bg-secondary-500 p-2"><h5 class="text-bold m-0 p-0">{{filterTitle}}</h5></div>
    <div class="container-fluid">
        <div class="row">
            <input type="text" class="form-control form-control-sm rounded-0 shadow-0" name="search" id="search"
            placeholder="Search..." (input)="filterList($event)">
        </div>
        <div class="overflow-auto custom-scroll slimScrollBar" style="height: 300px; width: 200px">
            <div class="py-1 AllRows" *ngFor="let option of options">
                <label class="filterdata">
                    <input type="checkbox" class="{{name}}-set-checkbox" id="{{name}}-{{getValue(option)}}" (change)="valueChanged($event)" value="{{getValue(option)}}"> &nbsp;{{option.name}}
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col p-0">
                <button (click)="apply()" class="btn btn-sm btn-block rounded-0 btn-success pull-right">Apply</button>
            </div>
            <div class="col p-0">
                <button (click)="clear()" class="btn btn-sm btn-block rounded-0 btn-danger pull-right">Clear</button>
            </div>
        </div>
    </div>
</div>
