import { UserAccessGroupEnum } from '@app/shared/models/groups.model';
import { ModuleEnum } from "./module.model";

export enum NavItemType {
    Menu,
    NavTitle
}
export const SettingsSubMenu = [
    {
        id: ModuleEnum.Workflows,
        title: 'Workflows',
        link: '/portal/settings/workflows/list',
        icon: 'fal fa-tasks',
        accessLevel: [UserAccessGroupEnum.Administrator],
    },
    {
        id: ModuleEnum.FieldOptions,
        title: 'Field Options',
        link: '/portal/settings/field-options',
        icon: 'fal fa-cogs',
        accessLevel: [UserAccessGroupEnum.Administrator],
    },
    {
        id: ModuleEnum.UsersDevices,
        title: 'Users Devices',
        link: '/portal/settings/devices',
        icon: 'fal fa-server',
        accessLevel: [UserAccessGroupEnum.Administrator],
    },
    {
        id: ModuleEnum.SMTPConnections,
        title: 'Default SMTP',
        link: '/portal/settings/default-smtp',
        icon: 'fal fa-at',
        accessLevel: [UserAccessGroupEnum.Administrator],
    },
    {
        id: ModuleEnum.ActivityLog,
        title: 'Activities Log',
        link: '/portal/settings/activities-log',
        icon: 'fal fa-task',
        accessLevel: [UserAccessGroupEnum.Administrator],
    },
    {
        id: 1003,
        title: 'Login History',
        link: '/portal/settings/login-history',
        icon: 'fal fa-list',
        accessLevel: [UserAccessGroupEnum.Administrator],
    },
    {
        id: ModuleEnum.SMTPConnections,
        title: 'SMTP Connections',
        link: '/portal/settings/smtp-connections',
        icon: 'fal fa-server',
        accessLevel: [UserAccessGroupEnum.Administrator],
    }
];
