import {
    Injectable
} from '@angular/core';
import {
    CanActivate,
    Router
} from '@angular/router';
import {
    ApplicationDataService
} from '@app/core/service/application-data.service';

@Injectable()
// use this in reverse, cannot find canNotActivate in router
export class ContractorOnlyGaurd implements CanActivate {
    constructor(
        private router: Router,
        private applicationDataService: ApplicationDataService) {}

    async canActivate() {
        return await this.evaluatePermissions();
    }

    async canLoad() {
        return await this.evaluatePermissions();
    }

    private evaluatePermissions = async () => {
        if (this.applicationDataService.isAppDataAvailable()) {
            await this.applicationDataService.setUserGroups();
            return this.doPermissionEvaluation();
        } else {
            await this.applicationDataService.refreshAppData()
            await this.applicationDataService.setUserGroups();
            return this.doPermissionEvaluation();
        }
    }

    private doPermissionEvaluation () {
        const contractor = this.applicationDataService.isContractor();
        if (contractor) {
            this.router.navigate(['/portal/page-not-accessible'], {
                replaceUrl: true
            });
        } else {
            return true;
        }
    }
}
