export interface UserPref {
    deposit_by_day: boolean;
    latest_activities: boolean;
    deposit_by_assignee: boolean;
    monthly_deposit_withdraw: boolean;
    daily_deposit_withdraw: boolean;
    lead_by_status: boolean;
    ticket_by_status: boolean;
    favourite_leads: boolean;
    favourite_Accounts: boolean;
    clients_by_status: boolean;
    preferred_currency: string;
}
export interface UserPrefResponse {
    user_preference: UserPref;
}
export interface HelpsResponse {
    user_preference: UserPref;
}
export interface UserPref {
    data?: UserPref;
    message?: string;
    user_id?: number;
    id?: number;
}


export enum PreferenceEnum {
    HIDDEN_WIDGETS = 710,
    LAST_DASHBOARD_TIMEFRAME = 800,
    LAST_DASHBOARD_CURRENCY = 801,
    LEADS_GRID_COLUMN_ORDER = 802,
    ACCOUNTS_GRID_COLUMN_ORDER = 803,
    DEPOSITORS_GRID_COLUMN_ORDER = 804,
}
