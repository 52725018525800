import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService, BaseAPIClass } from '@app/core';
import { TimeFrame, TIMEFRAMES_ARRAY } from '@app/shared/models/timeframes.model';
import { PreferenceEnum } from './user-pref-model/user-pref.model';
@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService extends BaseAPIClass {
  private readonly URL_PREFERENCES: string = 'users/preferences/update';
  private readonly URL_FETCH_PREFERENCE: string = 'preference/{id}';

  private preferencesSet: any = {};
  private preferedCurrency: String = null;
  private selectedTimeframe: TimeFrame = null;
  private dashboardFilterCountry: any = null;
  private dashboardFilterDesk: any = null;
  private defaultTimeframe: TimeFrame = TIMEFRAMES_ARRAY[3];
  private defaultCurrency: String = 'USD';

  constructor(
    protected httpClient: HttpClient,
    protected authenticationService: AuthenticationService) {
        super(httpClient, authenticationService);
    }

    public setPreferedCurrency(currency: string) {
        this.preferedCurrency = currency;
        this.updatePreference(PreferenceEnum.LAST_DASHBOARD_CURRENCY, currency);
    }

    public getPreferedCurrency() {
        if(this.preferedCurrency != null)
            return this.preferedCurrency;
        const preferenceValue = this.getPreferenceByPreferenceId(PreferenceEnum.LAST_DASHBOARD_CURRENCY);
        if(preferenceValue != null)
            return preferenceValue;
        return this.defaultCurrency;
    }

    public setDashboardFilterCountry(country: any) {
        this.dashboardFilterCountry = country;
    }

    public getDashboardFilterCountry() {
        return this.dashboardFilterCountry;
    }

    public setDashboardFilterDesk(desk: any) {
        this.dashboardFilterDesk = desk;
    }

    public getDashboardFilterDesk() {
        return this.dashboardFilterDesk;
    }

    public setTimeframe(start: TimeFrame) {
        this.selectedTimeframe = start;
        this.updatePreference(PreferenceEnum.LAST_DASHBOARD_TIMEFRAME, start.name);
    }

    public getTimeframe() {
        if(this.selectedTimeframe != null)
            return this.selectedTimeframe;

        let preferenceValue = this.getPreferenceByPreferenceId(PreferenceEnum.LAST_DASHBOARD_TIMEFRAME);
        if(preferenceValue != null) {
            for(const timeFrame of TIMEFRAMES_ARRAY){
                if(timeFrame.name == preferenceValue) {
                    preferenceValue = timeFrame;
                    break;
                }
            }
            return preferenceValue;
        }
        return this.defaultTimeframe;
    }

    public getPreferenceByPreferenceId(preferenceId: number) {
        if(preferenceId in this.getPreferencesSet()) {
            return this.getPreferencesSet()[preferenceId];
        }
    }

    public buildUserPreferencesStore(preferencesSet: object) {
        this.preferencesSet = preferencesSet;
    }

    public getPreferencesSet() {
        return this.preferencesSet;
    }

    public async updatePreference(preference_id: number, value: string) {
        return await this.postAsync<any>(this.URL_PREFERENCES, {preference_id, value }, {}, false).toPromise();
    }

    public async fetchPreferenceById(preferenceId: number) {
        return await this.getAsync(this.URL_FETCH_PREFERENCE.replace('{id}', preferenceId.toString()), null);
    }

    public getReportFilters() {
        return {
            from: this.getTimeframe().value[0],
            to: this.getTimeframe().value[1],
            desk: this.getDashboardFilterDesk(),
            country: this.getDashboardFilterCountry(),
            currency: this.getPreferedCurrency()
        }
    }
}
