<div class="page">
    <div id="whitebox" class="loginForm">
        <div class="text-center">
            <img src="./assets/images/public-eye.svg" aria-roledescription="logo" class="loginForm__logo"/>
        </div>
        <div class="loginForm__formContainer">
            <div id='mainContent' class='loginForm__mainContentContainer'>
                <div id='illustrationContainer' class='loginForm__illustrationContainer'>
                    <img src="assets/images/vector.png" class='loginForm__devImg' />
                </div>
                <form id='submitForm' class='loginForm__form' [formGroup]="loginForm" (ngSubmit)="login()" novalidate
                    autocomplete="off">
                    <div id='content' class='loginForm__content'>
                        <div id='passwordForm' data-next='checkPassword'>
                            <p class='loginForm__paragraph'>Login</p>
                            <div id='loginErrortop'
                                class='loginForm__statusMessageContainer statusMessageContainer__topHeight hidden'>
                                <div id='messagetop' class='loginForm__statusMessage'></div>
                            </div>
                            <app-error-list [errorsList]="errorsList"></app-error-list>
                            <div class="row" *ngIf="accountSignupEmailSent">
                                <div class="col-12">
                                    <div class='alert alert-success'>
                                        <p class="p-0 m-0">Signup successful, please check your email to activate your
                                            account.</p>
                                    </div>
                                </div>
                            </div>
                            <app-form-error-wrapper [control]="loginForm.controls['email']"
                            [controlName]="'Username or Email'">
                              <div class='labelGroup'>
                                  <label for='username' class='labelGroup__label'>Username or Email</label>
                              </div>
                            </app-form-error-wrapper>
                              <input type='email' id='email' class='loginForm__input' tabindex='1'
                                    formControlName="email" autofocus/>
                            <app-form-error-wrapper [control]="loginForm.controls['password']"
                                [controlName]="'Password'">
                                <div class='labelGroup'>
                                    <label for='password' class='labelGroup__label'>Password</label>
                                </div>
                              </app-form-error-wrapper>
                                <input type='password' id='password' name='password' class='loginForm__input'
                                    tabindex='1' formControlName="password" />
                               <a href="#" [routerLink]="'/auth/forgot_password'" ><p>Forgot Password?</p></a>
                        </div>
                    </div>
                    <div class='loginForm__buttonContainer'>
                        <button type='button' class='loginForm__button loginForm__buttonHidden' id='previous'
                            tabindex='3' visibility='hidden'>&#8592; Back</button>
                        <button type='submit' #loginBtn class='shadow-0 btn btn-info btn-block btn-lg' id='next' tabindex='2'
                            [disabled]="!loginForm.valid || httpProcess"> <i class="fal fa-spinner fa-spin mr-2"
                                *ngIf="httpProcess"></i>LOGIN</button>
                    </div>
                    <!-- <div class='stepLink__container'>
                        <div class='stepLink'>
                            Don't have an account yet? <a href='javascript:;' [routerLink]="['/auth/signup']" >Sign up now!</a>
                        </div>
                    </div> -->
                </form>
            </div>

        </div>
    </div>
    <div class="footer w-100">
        <div class="footer__companyInfo w-100 d-flex justify-content-center" id="copyright">
        </div>
    </div>
</div>
