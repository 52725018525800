import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordRequest } from './reset-password.model';
import { Alert } from '@app/shared/models/alert.model';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent extends AbstractBaseComponent implements OnInit {

    resetPasswordForm: FormGroup;
    private token: string;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private profileService: ProfileService,
        private route: ActivatedRoute) {
        super();

    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.token = params.token;
            if (this.token === '') {
                this.router.navigateByUrl('login?reason=invalid_reset_password_token');
            }
            this.createForm();
        });
    }

    private createForm() {
        this.resetPasswordForm = this.formBuilder.group({
            token: [this.token, [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            password_confirmation: ['', [Validators.required, Validators.minLength(6)]]
        }, { validator: this.passwordMatchingValidatior });
    }


    public passwordMatchingValidatior(form: FormGroup): any {
        const password = form.controls['password'];
        const confirmation = form.controls['password_confirmation'];
        if (!password.value || !confirmation.value) {
            return null;
        } 

        if (confirmation.value.length > 0 && confirmation.value !== password.value) {
            return confirmation.setErrors({ notMatch: true });
        } else if (confirmation.value.length > 0 && confirmation.value === password.value) {
            confirmation.setErrors(null);
        }
        return null;
    }
    get hasError() {
        return this.resetPasswordForm.controls['password_confirmation'].hasError('notMatch');
    }

    public async resetPassword() {
        try {
            const resetPasswordRequest: ResetPasswordRequest = this.resetPasswordForm.value;
            resetPasswordRequest.token = this.token;
            const resp = await this.profileService.resetPassword(resetPasswordRequest);

            const alert: Alert = {
                title: 'Password Recovery',
                sub_title: 'Request completed',
                body: 'You password has been updated successfuly.',
                ok_text: 'OK',
                close_text: 'CLOSE'
            };
            this.handleModalAlert(alert);
        } catch (error) {
            console.log(error);
            this.hasErrors = true;
            this.httpError = error;
        }
    }

    public handleModalAlert(alert: Alert) {
        this.alert = alert;
        (<any>$('#alert-modal-md')).modal();
    }

    public redirectBack() {
        this.router.navigateByUrl('login?reason=password_reset_successfuly');
    }
}
